import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Link from "./link"
import NewsPostPreview from "./news-post-preview"
import EventPreview from "./event-preview"
import { graphql, useStaticQuery } from "gatsby"

const AktuellesPreview = ({ noOfEvents, noOfNews }) => {
  const data = useStaticQuery(graphql`
    query AktuellesPreviewQuery {
      veranstaltungenPage: sanityPage(_id: { eq: "veranstaltungen" }) {
        id
        title
      }
      newsPage: sanityPage(_id: { eq: "news" }) {
        id
        title
      }
      news: allNewsPost(sort: { fields: [publishedAt], order: DESC }) {
        edges {
          node {
            id
            title
            isNewsV1
            publishedAt(formatString: "DD MMMM YYYY", locale: "de")
            slug {
              current
            }
            parent {
              ... on MarkdownRemark {
                excerpt
              }
              ... on SanityPost {
                id
                body {
                  children {
                    text
                    _type
                    marks
                    _key
                  }
                  _type
                }
              }
            }
          }
        }
      }
      events: allCalendarEvent(sort: { fields: [end___dateTime], order: ASC }) {
        edges {
          node {
            id
            summary
            start {
              dateTime
            }
            end {
              dateTime
            }
          }
        }
      }
    }
  `)
  const recentNews = data.news.edges.slice(0, noOfNews)
  const upcomingEvents = data.events.edges.slice(0, noOfEvents)

  const newsTitle = data.newsPage.title
  const veranstaltungenTitle = data.veranstaltungenPage.title

  return (
    <section className="mb-2">
      <Container style={{ maxWidth: "1024px" }}>
        <Row>
          <Col lg={6} className="mb-5 mb-lg-0">
            <h2 className="mb-3 mt-0 text-center text-lg-left">
              <Link to={`/news`}>{newsTitle}</Link>
            </h2>
            <div className="d-flex flex-column justify-content-between">
              {recentNews.map(({ node }) => (
                <NewsPostPreview
                  post={node}
                  key={node.id}
                  small
                  className="mb-4 text-center text-md-left"
                />
              ))}
            </div>
          </Col>
          <Col lg={6}>
            <h2 className="mb-3 mt-0 text-center text-lg-left">
              <Link to={`/veranstaltungen`}>{veranstaltungenTitle}</Link>
            </h2>
            <div className="d-flex flex-column justify-content-between">
              {upcomingEvents.length
                ? upcomingEvents.map(({ node }, index) => (
                    <EventPreview event={node} key={index} className="mb-3" />
                  ))
                : "Keine aktuellen Veranstaltungen"}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AktuellesPreview
