import React from "react"

import Layout from "../components/layout"
import Banner from "../components/banner"
import AktuellesPreview from "../components/aktuelles-preview"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Hero from "../components/hero"
import PageContent from "../components/page-content"

const IndexPage = ({ data }) => {
  const { image, hero, newsPreview, pageContent } = data.sanityPage

  return (
    <Layout>
      <Seo title="Home" />
      <Banner image={image}>
        <Hero title={hero.title} subtitle={hero.subtitle} cta={hero.cta} />
      </Banner>
      <AktuellesPreview
        noOfEvents={newsPreview.noEvents}
        noOfNews={newsPreview.noNews}
      />
      <Container style={{ maxWidth: "1024px" }}>
        <PageContent content={pageContent} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityPage(_id: { eq: "home" }) {
      id
      title
      _id
      image {
        alt
        asset {
          url
        }
      }
      hero {
        cta {
          title
          url {
            blank
            href
          }
        }
        subtitle
        title
      }
      pageContent {
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      newsPreview {
        noEvents
        noNews
      }
    }
  }
`

export default IndexPage
