import React from "react"
import PropTypes from "prop-types"

const CalendarLeaf = props => {
  const startDate = props.start
  const endDate = props.end
  const small = props.small
  const variant = props.variant ? props.variant : "primary"

  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ]

  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ]

  const SingleDate = props => {
    const month = props.small
      ? monthNamesShort[props.date.getMonth()]
      : monthNames[props.date.getMonth()]

    return (
      <div
        className={`d-flex flex-column align-items-center calendar-leaf calendar-leaf-${variant} ${
          small ? "calendar-leaf-sm" : ""
        }`}
      >
        <h1 className="calendar-leaf-date mb-0">{props.date.getDate()}</h1>
        <div className="calendar-leaf-month">{month}</div>
      </div>
    )
  }

  if (startDate.getDate() === endDate.getDate()) {
    return <SingleDate date={startDate} small={small} />
  }

  return (
    <>
      <SingleDate date={startDate} small={small} />
      <SingleDate date={endDate} small={small} />
    </>
  )
}

CalendarLeaf.propTypes = {
  start: PropTypes.any,
  end: PropTypes.any,
  small: PropTypes.bool,
}

CalendarLeaf.defaultProps = {
  start: new Date(),
  end: new Date(),
  small: false,
}

export default CalendarLeaf
